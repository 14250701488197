body {
  margin: 0;
  font-family:
    -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

#wrapper {
  width: 100%;
  overflow: hidden; /* will contain if #first is longer than #second */
}
#first {
  width: 50%;
  float: left; /* add this */
}
#second {
  overflow: hidden; /* if you don't want #second to wrap below #first */
}
